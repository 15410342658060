import React from 'react';
import { ActivityIndicator, View } from 'react-native';

const Loader = () => {
    return (
        <View style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, justifyContent: 'center', alignItems: 'center'}}>
            <View style={{position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, backgroundColor: 'black', opacity: 0.45}}/>
            <View style={{padding: 16, backgroundColor: 'white', borderRadius: 8}}>
                <ActivityIndicator style={{}} color='#6200EE'/>
            </View>
        </View>
    );
};

export default Loader;
