import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where, orderBy } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import { useDispatch } from 'react-redux';
import { setLga, setPhoto, setState } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { format } from 'date-fns';


export default function AllTechnicians() {
    let [customers, setCustomers] = react.useState([])
    let navigation = useNavigation();
    let [loading, setLoading] = react.useState(false)
    let dispatcher = useDispatch();
    let [approvedSelected, setApprovedSelected] = react.useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = react.useState(true)
    function renderCustomer({ item }) {
        return (
            <MD3.Card style={{padding: 16, marginTop: 8}} onPress={() => viewTechnician(item)}>
                <MD3.Caption>Technician Name</MD3.Caption>
                <MD3.Text>{item.fullName}</MD3.Text>
                <MD3.Caption>Contact</MD3.Caption>
                <MD3.Text>{item.username}, {item.emailId}</MD3.Text>
                <MD3.Caption>Installation Date</MD3.Caption>
                <MD3.Text>{(item.__createdOn && format(item.__createdOn.toDate(), 'd MMM, y') || '--')}</MD3.Text>
                <Octicons style={{position: 'absolute', right: 0, top: 0}} name="dot-fill" size={24} color={item.status!=='Approved'?MD3.MD2Colors.amberA700:MD3.MD2Colors.greenA700} />
            </MD3.Card>
        )
    }
    async function loadCustomers() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician"), orderBy("__createdOn", "desc")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
            setCustomers(_docs)
        })
        setLoading(false)
    }
    function viewTechnician(item){
        navigation.navigate('view-technician', {
            technician: item
        })
    }
    useFocusEffect(() => {
        console.log("useFocusEffect")
    })
    react.useEffect(() => {
        loadCustomers();
    }, [])
    let filteredCustomers = customers.filter(e => {
        return (e.status !== 'Approved' && pendingApprovalSelected) || (e.status === 'Approved' && approvedSelected)
    })
    let pendingApprovalCount = customers.filter(e => {
        return e.status !== 'Approved'
    }).length
    let approvedCount = customers.filter(e => {
        return e.status === 'Approved'
    }).length
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                data={[<View style={{ flex: 1, padding: 16 }}>
                    <View style={{flexDirection: 'row', marginTop: 16,marginBottom: 8}}>
                        <MD3.Chip selected={approvedSelected} onPress={() => setApprovedSelected(!approvedSelected)} style={{flexGrow: 1}}>Approved ({approvedCount})</MD3.Chip>
                        <View style={{width: 16}}/>
                        <MD3.Chip selected={pendingApprovalSelected} onPress={() => setPendingApprovalSelected(!pendingApprovalSelected)} style={{flexGrow: 1}}>Pending approval ({pendingApprovalCount})</MD3.Chip>
                    </View>
                    <FlatList
                        data={filteredCustomers}
                        renderItem={renderCustomer}
                        ListEmptyComponent={() => (
                            <MD3.Caption style={{  }}>No technicians available</MD3.Caption>
                        )}
                        keyExtractor={item => `customer-${item.id}`} />
                </View>]}
                renderItem={({ item }) => item}
                keyExtractor={(item, idx) => `dataitem-${idx}`}
            />
            {loading && <Loader />}
        </View>
    )
}