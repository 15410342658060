import * as React from 'react'
import { Alert, FlatList, Image, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
// import { Slider } from '@miblanchard/react-native-slider';
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import utils, { ADMIN_EMAIL, SLABS } from '../../utils';
import axios from 'axios';
import { Octicons } from '@expo/vector-icons';
import { useState, useEffect } from 'react';
import Toast from 'react-native-root-toast';

export default function () {
    let [loading, setLoading] = useState(false)
    let [user, setUser] = useState({})
    let [referrals, setReferrals] = useState([])
    async function loadReferral() {
        setLoading(true)
        let _user = await AsyncStorage.getItem('user')
        _user = JSON.parse(_user)
        setUser(_user)
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician"), where("referralCode", "==", _user.referral), orderBy("__createdOn", "desc")))
        let referredUsers = []
        docs.docs.forEach(doc => {
            referredUsers.push({
                ...doc.data(),
                id: doc.id
            })
        })
        setReferrals(referredUsers)
        setLoading(false)
    }
    useEffect(() => {
        setLoading(true)
        loadReferral()
        setLoading(false)
    }, [])
    return (
        <View>
            <MD3.Subheading style={{margin: 16, paddingVertical: 16}}>Referrals ({referrals.length})</MD3.Subheading>
            <FlatList
                data={[...referrals]}
                keyExtractor={item => item.id}
                ItemSeparatorComponent={() => <View style={{height: 8}}/>}
                renderItem={({item}) => {
                    return (
                        <MD3.Card style={{marginHorizontal: 16, padding: 16}}>
                            <View>
                                <MD3.Text style={{fontWeight: 'bold'}}>{item.fullName}</MD3.Text>
                                <MD3.Caption>Joined on: {format(item.__createdOn.toDate(), 'd MMM, y')}</MD3.Caption>
                                <MD3.Text>Referral Code: {item.referralCode}</MD3.Text>
                            </View>
                        </MD3.Card>
                    )
                }}/>
            {loading && <Loader/>}
        </View>
    )
}