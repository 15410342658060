import * as React from 'react'
import { Alert, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../firebase-app';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format, parse } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../defaultSlice';
import Loader from '../loader';
import { SLABS, AUDITORS } from '../utils';
import * as utils from '../utils'
import Toast from 'react-native-root-toast';

const storage = getStorage();

export default function ViewCustomerAdmin() {

    let ref1 = React.useRef();
    let ref2 = React.useRef();
    let ref3 = React.useRef();
    let ref4 = React.useRef();
    let ref5 = React.useRef();
    let ref6 = React.useRef();
    let ref7 = React.useRef();
    let ref8 = React.useRef();
    let ref9 = React.useRef();
    let ref10 = React.useRef();
    let ref11 = React.useRef();

    let [visible, setVisible] = React.useState(false)
    let [selectedImage, setSelectedImage] = React.useState([])
    
    let route = useRoute();
    let customer = route.params.customer;

    let [shwPurchaseDate, setShowPurchaseDate] = React.useState()
    let [shwInstallationDate, setShowInstallationDate] = React.useState()

    let store = useSelector(state => state.default);

    let navigation = useNavigation()
    let [loading, setLoading] = React.useState(false)
    let [purchaseDate, setPurchaseDate] = React.useState(new Date())
    let [installationDate, setInstallationDate] = React.useState(new Date())
    let dispatch = useDispatch();
    let [state, setState] = React.useState({
        fullName: customer.fullName,
        fullAddress: customer.fullAddress,
        zipCode: customer.zipCode,
        mobileNumber: customer.mobileNumber,
        emailId: customer.emailId,
        productCode: customer.productCode,
        serialNumber: customer.serialNumber,
        storeName: customer.storeName,
        lga: customer.lga,
        invoiceNumber: customer.invoiceNumber,
        purchaseDate: '',
        installationDate: ''
    })
    function setValue(data) {
        setState({
            ...state,
            ...data
        })
    }

    function setIsVisible(show){
        setVisible(show)
    }

    async function saveCustomer() {
        let customerId = uuidv4();
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let user = JSON.parse(userData)
        await setDoc(doc(database, "customers", customerId), {
            ...state,
            lga: store.selectedState + ', ' + store.selectedLga,
            picInstallation: store.picInstallation,
            picProduct: store.picProduct,
            picSheet: store.picSheet,
            purchaseDate,
            installationDate,
            user: user.id,
            technician: user,
            status: 'Pending'
        })
        setLoading(false)
        //ToastAndroid.show('Customer created!', ToastAndroid.SHORT)
        Toast.show('Customer created world', {
            duration: Toast.durations.SHORT
        }) 
        navigation.goBack()
    }

    function openLGA() {
        navigation.navigate('select-state')
    }

    let [focusedInput, setFocusedInput] = React.useState(0)

    function viewPicture(type){
        var imageUrl = "";
        switch(type){
            case 'installation':
                imageUrl = customer.picInstallation;
                break;
            case 'product':
                imageUrl = customer.picProduct;
                break;
            case 'sheet':
                imageUrl = customer.picSheet;
                break;
        }
        setSelectedImage([{
            uri: utils.getImageUrl(imageUrl),
        }])
        setVisible(true)
    }
    function openPurchaseDate() {
        setShowPurchaseDate(true)
    }
    function openInstallationDate() {
        setShowInstallationDate(true)
    }
    async function approveCustomer(){
        setLoading(true)
        let userId = customer.technician.id
        let _user = await getDoc(doc(database, "users", userId))
        await setDoc(doc(database, "customers", customer.id), {
            ...customer,
            status: 'Approved'
        })
        let userData = _user.data();
        let slab = SLABS.findSlab((userData.points + 1) || 0)
        await setDoc(doc(database, "users", userId), {
            ..._user.data(),
            points: +(_user.data().points || 0) + (slab?.Multiplier || 0),
            totalPoints: +(_user.data().points || 0) + (slab?.Multiplier || 0)
        })
        AUDITORS.auditPoints(_user.data(), slab?.Multiplier || 0, "New customer registration", {'customer_name': userData.fullName})
        setLoading(false)
        //ToastAndroid.show('Customer approved', ToastAndroid.SHORT)
        Toast.show('Customer approved', {
            duration: Toast.durations.SHORT
        }) 
        navigation.pop(2)
    }
    return (
        <View>
            <ScrollView style={{ margin: 16 }} showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16, backgroundColor: 'white', borderRadius: 8 }}>
                    <MD3.Title style={{ fontWeight: 'bold' }}>Installation Registration</MD3.Title>
                    <MD3.Subheading>Describe customer details along with product information</MD3.Subheading>
                    <MD3.TextInput editable={false} defaultValue={state.fullName} onChangeText={value => {
                        setValue({
                            'fullName': value
                        })
                    }} label='Customer full name' mode='outlined' style={{ marginTop: 8 }} returnKeyType='next' autoFocus={focusedInput === 0} blurOnSubmit={false} onSubmitEditing={() => ref1.current?.focus()} />
                    <MD3.TextInput editable={false} defaultValue={state.fullAddress} onChangeText={value => {
                        setValue({
                            'fullAddress': value
                        })
                    }} label='Full address' numberOfLines={3} multiline mode='outlined' style={{ marginTop: 8 }} ref={ref1} returnKeyType='next' />
                    {/* <MD3.TextInput editable={false} defaultValue={state.zipCode} onChangeText={value => {
                        setValue({
                            'zipCode': value
                        })
                    }} label='Zip Code' mode='outlined' style={{ marginTop: 8 }} ref={ref2} returnKeyType='next' autoFocus={focusedInput === 2} blurOnSubmit={false} onSubmitEditing={() => ref3.current?.focus()} /> */}
                    <MD3.TextInput editable={false} defaultValue={state.mobileNumber} onChangeText={value => {
                        setValue({
                            'mobileNumber': value
                        })
                    }} label='Mobile Number' mode='outlined' style={{ marginTop: 8 }} ref={ref3} keyboardType='phone-pad' returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref4.current?.focus()} />
                    <MD3.TextInput editable={false} defaultValue={state.emailId} onChangeText={value => {
                        setValue({
                            'emailId': value
                        })
                    }} label='Email Id' mode='outlined' style={{ marginTop: 8 }} ref={ref4} returnKeyType='next' keyboardType='email-address' blurOnSubmit={false} onSubmitEditing={() => ref5.current?.focus()} />
                    <MD3.TextInput editable={false} defaultValue={state.productCode} onChangeText={value => {
                        setValue({
                            'productCode': value
                        })
                    }} label='Product Code' mode='outlined' style={{ marginTop: 8 }} ref={ref5} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref6.current?.focus()} />
                    <MD3.TextInput editable={false} defaultValue={state.serialNumber} onChangeText={value => {
                        setValue({
                            'serialNumber': value
                        })
                    }} label='Product Serial Number' mode='outlined' style={{ marginTop: 8 }} ref={ref6} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref7.current?.focus()} />
                    <MD3.TextInput editable={false} defaultValue={state.storeName} onChangeText={value => {
                        setValue({
                            'storeName': value
                        })
                    }} label='Dealer Store name (Optional)' mode='outlined' style={{ marginTop: 8 }} ref={ref7} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref8.current?.focus()} />
                    {/* <MD3.TextInput disabled={loading} editable={!loading} defaultValue={state.lga} onChangeText={value => {
                        setValue({
                            'lga':value
                        })
                    }} label='LGA' mode='outlined' style={{marginTop: 8}} ref={ref8} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref9.current?.focus()} /> */}
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>LGA</MD3.Caption>
                        <TouchableOpacity style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            <MD3.Subheading>{customer.lga}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>
                    <MD3.TextInput disabled={loading} editable={false} defaultValue={state.invoiceNumber} onChangeText={value => {
                        setValue({
                            'invoiceNumber': value
                        })
                    }} label='Invoice Number (Optional)' mode='outlined' style={{ marginTop: 8 }} ref={ref9} />
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>Purchase Date</MD3.Caption>
                        <TouchableOpacity style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            {/* <MD3.Subheading>{(store.selectedState && store.selectedLga)?`${store.selectedLga}, ${store.selectedState}`:'Select LGA'}</MD3.Subheading> */}
                            <MD3.Subheading>{format(parse( customer.purchaseDate, 'y-MM-dd', new Date()), 'd MMM, y')}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 8 }}>
                        <MD3.Caption>Created on</MD3.Caption>
                        <TouchableOpacity style={{ backgroundColor: MD3.MD3Colors.primary99, paddingVertical: 10, paddingHorizontal: 16, borderRadius: 4, borderColor: MD3.MD3Colors.neutral50, borderWidth: 1 }}>
                            {/* <MD3.Subheading>{(store.selectedState && store.selectedLga)?`${store.selectedLga}, ${store.selectedState}`:'Select LGA'}</MD3.Subheading> */}
                            <MD3.Subheading>{format(parse( customer.installationDate, 'y-MM-dd', new Date()), 'd MMM, y')}</MD3.Subheading>
                        </TouchableOpacity>
                    </View>

                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={'camera'} onPress={() => viewPicture('installation')}>View Picture of Installation</MD3.Button>
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={'camera'} onPress={() => viewPicture('product')}>View Picture of Product serial number</MD3.Button>
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={'camera'} onPress={() => viewPicture('sheet')}>View Installation Sheet</MD3.Button>
                    <MD3.Button icon='shield' color='green' onPress={approveCustomer} loading={loading} disabled={loading || customer.status === 'Approved'} mode='contained' style={{ marginTop: 16 }}>Approve</MD3.Button>
                </View>
            </ScrollView>
            {/* <ImageView
                images={selectedImage}
                imageIndex={0}
                visible={visible}
                onRequestClose={() => setIsVisible(false)}
            /> */}
            {loading && <Loader/>}
        </View>
    )
}