import * as React from 'react'
import { Alert, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setDefaultCustomer, setLga, setPhoto, setProducts, setState as setLgaState } from '../../defaultSlice';
import Loader from '../../loader';
import axios from 'axios';
import { Feather } from '@expo/vector-icons';
import utils, { ADMIN_EMAIL, validPhoneNumber } from '../../utils';
import { useEffect } from 'react';
import Toast from 'react-native-root-toast';
const storage = getStorage();

export default function ViewProduct() {

    let ref1 = React.useRef();
    let ref2 = React.useRef();
    let ref3 = React.useRef();
    let ref4 = React.useRef();
    let ref5 = React.useRef();
    let ref6 = React.useRef();
    let ref7 = React.useRef();
    let ref8 = React.useRef();
    let ref9 = React.useRef();
    let ref10 = React.useRef();
    let ref11 = React.useRef();

    let [shwPurchaseDate, setShowPurchaseDate] = React.useState()
    let [shwInstallationDate, setShowInstallationDate] = React.useState()

    let store = useSelector(state => state.default);

    let navigation = useNavigation()
    let [loading, setLoading] = React.useState(false)
    let [purchaseDate, setPurchaseDate] = React.useState(new Date())
    let [installationDate, setInstallationDate] = React.useState(new Date())
    let dispatch = useDispatch();
    let [state, setState] = React.useState({
        productCode: '',
        serialNumber: '',
        price: ''
    })
    function setValue(data) {
        setState({
            ...state,
            ...data
        })
    }

    useEffect(() =>{
        if(!!store.defaultProduct){
            console.log("Setting up defaultProduct", store.defaultProduct)
            setState({
                ...state,
                productCode: store.defaultProduct?.productCode,
                serialNumber: store.defaultProduct?.serialNumber,
                price: store.defaultProduct?.price,
            })
            dispatch(setPhoto('product', store.defaultProduct?.picProduct))
        }else{
            setState({
                productCode: '',
                serialNumber: '',
                price: ''
            })
        }
    }, [store.defaultCustomer])

    async function saveProduct() {
        if(!store.picProduct){
            //ToastAndroid.show('Please add photo for this product', ToastAndroid.SHORT)
            Toast.show('Please add  photo for this product', {
                duration: Toast.durations.SHORT
            }) 
            return;
        }
        let productId = store.defaultProduct.id;
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let user = JSON.parse(userData)
        await setDoc(doc(database, "products", productId), {
            ...state,
            picProduct: store.picProduct || store.defaultProduct.picProduct,
            user: user.id
        }, {
            merge: true
        })
        setLoading(false)
        //ToastAndroid.show('Product updated!', ToastAndroid.SHORT)
        Toast.show('Product updated', {
            duration: Toast.durations.SHORT
        }) 
        await loadProducts();
        navigation.goBack()
    }

    async function loadProducts() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "products")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
            //setProducts(_docs)
        })
        dispatch(setProducts(_docs))
        setLoading(false)
    }

    let [focusedInput, setFocusedInput] = React.useState(0)

    async function uploadGalleryPhoto(photo, type) {
        setLoading(true)
        let height = photo.height;
        let width = photo.width;
        let expectedHeight = Math.min(1080, height);
        let expectedWidth = (width / height) * expectedHeight;
        let image = await manipulateAsync(photo.uri, [{
            resize: {
                width: expectedWidth,
                height: expectedHeight
            }
        }], {
            compress: 0.75,
            base64: true
        })
        let storageRef = ref(storage, type + '/' + uuidv4() + ".jpg");
        let f = await fetch(image.uri)
        let blob = await f.blob()
        let snapshot = await uploadBytes(storageRef, blob)
        dispatch(setPhoto({
            type: type,
            url: snapshot.metadata.fullPath
        }))
        setLoading(false)
    }

    function pickImage(type) {
        ImagePicker.getMediaLibraryPermissionsAsync().then(permission => {
            if (permission.granted) {
                ImagePicker.launchImageLibraryAsync({
                    quality: 0.75,
                    allowsMultipleSelection: false
                }).then(media => {
                    uploadGalleryPhoto(media, type)
                })
            }
        })
    }

    function takePicture(type) {
        Alert.alert('Select photo', 'How do you want to upload the photo?', [{
            text: 'Gallery',
            onPress: () => {
                pickImage(type)
            }
        }, {
            text: 'Camera',
            onPress: () => {
                navigation.navigate('take-photo', {
                    type
                })
            }
        }],{
            cancelable: true
        })
    }

    useEffect(() => {
        dispatch(setDefaultCustomer(null))
    }, [])

    return (
        <View style={{flex: 1}}>
            <ScrollView style={{ margin: 16 }} showsVerticalScrollIndicator={false}>
                <View style={{ padding: 16, backgroundColor: 'white', borderRadius: 8 }}>
                    <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <MD3.Title style={{ fontWeight: 'bold' }}>Product Details</MD3.Title>
                    </View>
                    <MD3.TextInput disabled={loading} editable={false} value={state.productCode} onChangeText={value => {
                        setValue({
                            'productCode': value
                        })
                    }} label='Product Code' mode='outlined' style={{ marginTop: 8 }} returnKeyType='next' autoFocus={focusedInput === 0} blurOnSubmit={false} onSubmitEditing={() => ref1.current?.focus()} />
                    <MD3.TextInput disabled={loading} editable={false} value={state.serialNumber} onChangeText={value => {
                        setValue({
                            'serialNumber': value
                        })
                    }} label='Serial Number' mode='outlined' ref={ref1} style={{ marginTop: 8 }} returnKeyType='next' blurOnSubmit={false} onSubmitEditing={() => ref2.current?.focus()} />
                    
                    <MD3.TextInput disabled={loading} editable={!loading} value={state.price} onChangeText={value => {
                        setValue({
                            'price': value
                        })
                    }} label='Price' keyboardType='decimal-pad' mode='outlined' style={{ marginTop: 8 }} ref={ref2} returnKeyType='next' />
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon={!!store.picProduct ? 'check' : 'camera'} onPress={() => takePicture('product')}>{!!store.picProduct && 'Retake'} Picture of Product</MD3.Button>
                    <MD3.Button onPress={saveProduct} loading={loading} disabled={loading || !state.productCode || !state.price || !state.serialNumber || !store.picProduct} mode='contained' style={{ marginTop: 16 }}>Save Product</MD3.Button>
                </View>
            </ScrollView>
            {loading && <Loader/>}
        </View>
    )
}