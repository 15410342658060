import * as React from 'react'
import { ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import { FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native';
import {states} from './states.js'

export default function SelectLGAComponent() {
    let stateKeys = Object.keys(states)
    let navigation = useNavigation()
    function selectState(selectedState){
        navigation.navigate('select-lga', {
            state: selectedState
        })
    }
    return (
        <View>
            <FlatList
                data={stateKeys}    
                ItemSeparatorComponent={() => <MD3.Divider/>}            
                renderItem={({ item, index }) => {
                    return <TouchableOpacity style={{backgroundColor: 'white', padding: 16}} onPress={() => {
                        selectState(item)
                    }}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                            <MD3.Text>{item}</MD3.Text>
                        </View>
                    </TouchableOpacity>
                }}
                keyExtractor={(item, index) => `lga-${index}`} />
        </View>
    )
}
