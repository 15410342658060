import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  selectedState: '',
  selectedLga: '',
  picInstallation: null,
  picProduct: null,
  picSheet: null,
  profilePic: null,
  customers: [],
  technicians: [],
  approvals: [],
  defaultTechnician: null,
  defaultCustomer: null,
  defaultProduct: null,
  defaultSelectedProduct: null,
  products: []
}

export const defaultSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    resetStore: (state, action) => {
      state.value = 0;
      state.selectedLga = '';
      state.selectedState = '';
      state.picInstallation = null;
      state.picProduct = null;
      state.picSheet = null;
      state.profilePic = null;
      state.customers = [];
      state.technicians = [];
      state.approvals = [];
      state.defaultTechnician = null;
      state.defaultCustomer = null;
      state.picProduct = null;
      state.products = [];
      state.defaultSelectedProduct = null;
      state.defaultProduct = null;
    },
    setState: (state, action) => {
      state.selectedState = action.payload;
    },
    setLga: (state, action) => {
      state.selectedLga = action.payload;
    },
    setPhoto: (state, action) => {
      if(action.payload.type == 'installation') {
        state.picInstallation = action.payload.url;
      } else if(action.payload.type == 'product') {
        state.picProduct = action.payload.url;
      }else if(action.payload.type == 'sheet') {
        state.picSheet = action.payload.url;
      }else if(action.payload.type == 'profile') {
        state.profilePic = action.payload.url;
      } else if(action.payload.type == 'prodcut'){
        state.picProduct = action.payload.url;
      }
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setTechnicians: (state, action) => {
      state.technicians = action.payload;
    },
    setApprovals: (state, action) => {
      state.approvals = action.payload;
    },
    setDefaultTechnician: (state, action) => {
      state.defaultTechnician = action.payload;
    },
    setDefaultCustomer: (state, action) => {
      state.defaultCustomer = action.payload;
    },
    setDefaultProduct: (state, action) => {
      state.defaultProduct = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.defaultSelectedProduct = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setState, setLga, setPhoto, setCustomers, setTechnicians, setApprovals, setDefaultTechnician, resetStore, setDefaultCustomer, setDefaultProduct, setProducts, setSelectedProduct } = defaultSlice.actions

export default defaultSlice.reducer