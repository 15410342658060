import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import { useDispatch } from 'react-redux';
import { setDefaultTechnician, setLga, setPhoto, setState } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';


export default function ListTechnicians() {
    let [customers, setCustomers] = react.useState([])
    let navigation = useNavigation();
    let [loading, setLoading] = react.useState(false)
    let [searchQuery, setQuery] = useState('')
    let dispatcher = useDispatch();
    let [approvedSelected, setApprovedSelected] = react.useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = react.useState(true)
    function renderCustomer({ item }) {
        return (
            <MD3.Card style={{padding: 16, marginTop: 8}} onPress={() => selectTechnician(item)}>
                <MD3.Caption>Technician Name</MD3.Caption>
                <MD3.Text>{item.fullName}</MD3.Text>
                <MD3.Caption>Contact</MD3.Caption>
                <MD3.Text>{item.username}</MD3.Text>
                <MD3.Text>{item.emailId}</MD3.Text>
            </MD3.Card>
        )
    }
    async function loadCustomers() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "users"), where("type", "==", "Technician")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
        })
        setCustomers(_docs)
        setLoading(false)
    }
    function selectTechnician(item){
        dispatcher(setDefaultTechnician(JSON.parse(JSON.stringify(item))))
        navigation.goBack();
    }
    useFocusEffect(() => {
        console.log("useFocusEffect")
    })
    react.useEffect(() => {
        loadCustomers();
    }, [])
    let filteredCustomers = !!searchQuery?(customers.filter(e => e.fullName.toLowerCase().includes(searchQuery.toLowerCase().trim()))):customers;
    return (
        <View style={{ flex: 1 }}>
            <MD3.TextInput defaultValue={searchQuery} onChangeText={setQuery} placeholder='Search' label='Search' mode='outlined' left={<MD3.TextInput.Icon icon="magnify" />} style={{marginHorizontal: 16, marginTop: 16}} />
            <FlatList
                data={[<View style={{ flex: 1, padding: 16 }}>
                    <FlatList
                        data={filteredCustomers}
                        renderItem={renderCustomer}
                        ListEmptyComponent={() => (
                            <MD3.Caption style={{  }}>No technicians available</MD3.Caption>
                        )}
                        keyExtractor={item => `customer-${item.id}`} />
                </View>]}
                renderItem={({ item }) => item}
                keyExtractor={(item, idx) => `dataitem-${idx}`}
            />
            {loading && <Loader />}
        </View>
    )
}