import * as React from 'react'
import { Alert, FlatList, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format, isAfter, parse, subDays, subMonths } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../firebase-app';
import utils, { ADMIN_EMAIL, SLABS } from '../../utils';
import axios from 'axios';
import { Octicons } from '@expo/vector-icons';
import { useState } from 'react';
import collect from 'collect.js';
import otpGenerators from 'otp-generators';
import { Dimensions } from 'react-native'
import { Ionicons } from '@expo/vector-icons';
//import { LineChart } from 'react-native-chart-kit';

export default function RedeemDashboard() {
    let [user, setUser] = React.useState({})
    let [redDialogVisible, setRedDialogVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [history, setHistory] = useState([])
    let [customers, setCustomers] = useState([])
    let [monthGroupedCustomer, setMonthGroupedCustomer] = useState({})
    let [groupedCustomers, setGroupedCustomers] = useState([])
    let [refTechCount, setReferredTechsCount] = useState(0)
    let [chartData, setChartData] = useState({
        labels: [''],
        datasets: [{
            data: [0]
        }]
    })
    let navigation = useNavigation()
    const screenWidth = Dimensions.get('window').width
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [{
          data: [ 20, 45, 28, 80, 99, 43 ]
        }]
      }
    function hideRedDialog(){
        setRedDialogVisible(false)
    }
    async function loadUser(){
        setLoading(true)
        let _data = await AsyncStorage.getItem('user')
        console.log("Stored User", _data)
        let _user = await getDoc(doc(database, "users", JSON.parse(_data).id));
        console.log("**********USER SESSION*************")
        if(!_user.data().fullName){
            Alert.alert('Please restart app.','Cannot load user profile. Please restart app.', [], {cancelable: false})
            return;
        }
        console.log(_user.data())
        let code = otpGenerators.generate(6, {
            alphabets: true,
            uppercase: true
        }).toUpperCase()
        if (!!_user.data().referral) {            
            code = _user.data().referral
        }else{
            await setDoc(doc(database, "users", _user.id), {
                ...user,
                referral: code
            }, {
                merge: true
            })
        }
        setUser({
            ..._user.data(),
            referral: code,
            id: _user.id,
        })
        let customers = await getDocs(query(collection(database, "customers"), where("user", "==", _user.id), where("status", "==", "Approved"), orderBy("installationDate", "desc")))
        let sixMonthCustomersRes = await getDocs(query(collection(database, "customers"), where("user", "==", _user.id), where("status", "==", "Approved")));
        let sixMonthCustomers = sixMonthCustomersRes.docs.map(e => {
            return {
                id: e.id,
                ...e.data(),
                month: format(parse(e.data().installationDate, 'y-MM-dd', new Date()), 'MMM')
            }
        }).filter(e => {
            // where("installationDate", ">=", subDays(subMonths(new Date(), 6), 1))
            return isAfter(parse(e.installationDate, 'y-MM-dd', new Date()), subDays(subMonths(new Date(), 6), 1))
        })
        let allCustomers = customers.docs.map(e => {
            return {
                id: e.id,
                ...e.data()
            }
        });
        allCustomers = allCustomers.map(e => {
            return {
                ...e,
                createdDate: parse(e.installationDate, 'y-MM-dd', new Date()),
                createdMonth: format(parse(e.installationDate, 'y-MM-dd', new Date()), 'MMMM'),
                createdMonthNumber: format(parse(e.installationDate, 'y-MM-dd', new Date()), 'M'),
                createdYear: format(parse(e.installationDate, 'y-MM-dd', new Date()), 'y')
            }
        })
        setCustomers(collect(allCustomers).take(5).toArray())
        let _groupedCustomer = collect(allCustomers).where('createdYear', format(new Date(), 'y')).groupBy('createdMonth').all();
        setGroupedCustomers(_groupedCustomer)
        let _monthGrouped = collect(sixMonthCustomers).groupBy('month').all();
        let referredTechs = await getDocs(query(collection(database, "users"), where("referralCode", "==", code)))
        setReferredTechsCount(referredTechs.size)
        let _history = await getDocs(query(collection(database, "redeem_reqeusts"), where("requestedBy", "==", JSON.parse(_data).id), where("status", "==", "Approved"), orderBy("timestamp", "desc"), limit(1)))
        
        setHistory(_history.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }))
        setMonthGroupedCustomer(_monthGrouped)
        let __keys = Object.keys(_monthGrouped);
        let monthWiseCounts = []
        for(let i = 0; i< __keys.length;i++){
            let key = __keys[i]
            monthWiseCounts.push(collect(_monthGrouped[key]).count())
        }
        setChartData({
            labels: __keys,
            datasets: [{
                data: monthWiseCounts
            }]
        })
        setLoading(false)
    }
    function openCustomer(customer){
        navigation.navigate('view-customer', {
            customer
        })
    }
    React.useEffect(() => {
        loadUser();
    }, [])
    let slab = SLABS.findSlab(user.points);
    return (
        <View style={{flex: 1}}>
            <ScrollView style={{padding: 16, flex: 1}}>
                <View>
                    <MD3.Headline style={{fontWeight:'bold'}}>
                        Hello {user.fullName?.split(" ")[0]}!
                    </MD3.Headline>
                    <Ionicons onPress={loadUser} name="reload" size={24} color="black" style={{position: 'absolute', right: 16, top: 8}} />
                </View>
                {/* {user.points <= 50 && <MD3.Caption>You are a Silver Member</MD3.Caption>}
                {(user.points >= 51 && user.points <= 250) && <MD3.Caption>You are a Gold Member</MD3.Caption>}
                {(user.points >= 251 && user.points <= 500) && <MD3.Caption>You are a Diamond Member</MD3.Caption>}
                {(user.points >= 501) && <MD3.Caption>You are a Platinum Member</MD3.Caption>} */}
                <MD3.Caption>You are a {slab?.label} Member</MD3.Caption>
                <MD3.Card style={{marginVertical: 16, padding: 16}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        {(slab?.label == 'Silver') && <Image source={require('./silver.png')} style={{width: 48, height: 48}}/>}
                        {(slab?.label == 'Gold') && <Image source={require('./gold.png')} style={{width: 48, height: 48}}/>}
                        {(slab?.label == 'Diamond') && <Image source={require('./diamond.png')} style={{width: 48, height: 48}}/>}
                        {(slab?.label == 'Platinum') && <Image source={require('./platinum.png')} style={{width: 48, height: 48}}/>}
                        <View style={{flexDirection: 'row-reverse', flexGrow: 1, alignItems: 'baseline'}}>
                            <MD3.Text>&nbsp;Points</MD3.Text>
                            {/* <MD3.Text style={{fontWeight: 'bold', fontSize: 24}}>/{user.totalPoints || 0}</MD3.Text> */}
                            <MD3.Text style={{fontWeight: 'bold', fontSize: 48}}>{user.points||0}</MD3.Text>
                        </View>
                    </View>
                </MD3.Card>
                <MD3.Caption>LAST 5 CUSTOMERS</MD3.Caption>
                <View>
                    <FlatList
                        data={customers}
                        horizontal
                        style={{marginTop: 8, marginBottom: 16}}
                        showsHorizontalScrollIndicator={false}
                        ListEmptyComponent={() => <MD3.Caption style={{fontStyle: 'italic'}}>No customers onboarded yet</MD3.Caption>}
                        keyExtractor={e => e.id}
                        renderItem={({item}) => {
                            return <MD3.Card onPress={() => {
                                openCustomer(item)
                            }} style={{padding: 16, alignItems: 'center', maxWidth: 150, minWidth: 150}}>
                                <View style={{flexDirection: 'row', justifyContent: 'center'}}>
                                    <MD3.Avatar.Text label={item.fullName[0]} color='white'></MD3.Avatar.Text>
                                </View>
                                <MD3.Subheading style={{textAlign: 'center'}}>{item.fullName}</MD3.Subheading>
                            </MD3.Card>
                        }}/>
                </View>
                {/* {(chartData.datasets.length > 0 && chartData.datasets[0].data.length > 0) && <LineChart
                    data={chartData}
                    width={screenWidth - 32}
                    height={220}
                    chartConfig={{
                        backgroundColor: '#ffffff',
                        backgroundGradientFrom: 'white',
                        backgroundGradientTo: 'white',
                        color: (opacity = 1) => `rgba(0, 200, 255, ${opacity})`,
                    }}
                />} */}
                <MD3.Caption>Referral Code</MD3.Caption>
                <MD3.Title>{user.referral}</MD3.Title>
                <MD3.Caption>Total Technicians referred</MD3.Caption>
                <MD3.Title>{refTechCount}</MD3.Title>
                <MD3.Caption>Last Redeemption</MD3.Caption>

                {history.map((item, index) => (<MD3.Card key={`h-${index}`} style={{padding: 16}}>
                    <MD3.Caption>Points</MD3.Caption>
                    <MD3.Text>{item.requestedPoints} (₦{(item.requestedPoints*25).toFixed(2)})</MD3.Text>
                    <MD3.Caption>Date</MD3.Caption>
                    <MD3.Text>{format(item.timestamp.toDate(), 'd MMM, y')}</MD3.Text>
                    <Octicons style={{position: 'absolute', right: 0, top: 0}} name="dot-fill" size={24} color={item.status==='Pending'?MD3.MD2Colors.amberA700:item.status!=='Rejected'?MD3.MD2Colors.greenA700:MD3.MD2Colors.redA700} />
                </MD3.Card>))}

                <View style={{paddingBottom: 32}} />

                {/* <FlatList
                    data={history}
                    ListEmptyComponent={() => <MD3.Caption style={{fontStyle: 'italic'}}>No records available</MD3.Caption>}
                    keyExtractor={(item, index) => `h-${index}`}
                    renderItem={({item}) => {
                        return <MD3.Card style={{padding: 16}}>
                            <MD3.Caption>Points</MD3.Caption>
                            <MD3.Text>{item.requestedPoints} (₦{item.requestedPoints*25})</MD3.Text>
                            <MD3.Caption>Date</MD3.Caption>
                            <MD3.Text>{format(item.timestamp.toDate(), 'd MMM, y')}</MD3.Text>
                            <Octicons style={{position: 'absolute', right: 0, top: 0}} name="dot-fill" size={24} color={item.status==='Pending'?MD3.MD2Colors.amberA700:MD3.MD2Colors.greenA700} />
                        </MD3.Card>
                    }}/> */}
            </ScrollView>
            {loading && <Loader/>}
        </View>
    )
}