import * as React from 'react'
import { Alert, ScrollView, Share, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { getImageUrl } from '../../utils';
import otpGenerators from 'otp-generators';
import * as Sharing from 'expo-sharing';
import Toast from 'react-native-root-toast';
const storage = getStorage();


export default function EditProfile() {
    let [user, setUser] = React.useState({})
    let [loading, setLoading] = React.useState(false)
    let store = useSelector(state => state.default)
    let navigation = useNavigation()
    let dispatch = useDispatch();
    async function loadUserProfile() {
        setLoading(true)
        let userData = await AsyncStorage.getItem('user')
        let userId = JSON.parse(userData).id;
        let _data = await getDoc(doc(database, "users", userId))

        dispatch(setPhoto({
            type: 'profile',
            url: _data.data().profilePic
        }))
        setUser({
            ..._data.data(),
            id: userId
        })
        setLoading(false)
    }
    async function saveUser() {
        setLoading(true);
        await setDoc(doc(database, "users", user.id), {
            ...user
        }, {
            merge: true
        })
        setLoading(false)
        //ToastAndroid.show('Profile updated successfully', ToastAndroid.SHORT)
        Toast.show('Profile updated successfully', {
            duration: Toast.durations.SHORT
        }) 
    }
    async function shareApp() {
        if (!!user.referral) {
            await Share.share({
                message: 'Download the StarPlusBattery app and earn 15 points. User referral code: ' + user.referral
            })
        } else {
            setLoading(true)
            let code = otpGenerators.generate(6, {
                alphabets: true,
                uppercase: true
            }).toUpperCase()
            await setDoc(doc(database, "users", user.id), {
                ...user,
                referral: code
            }, {
                merge: true
            })
            setUser({
                ...user,
                referral: code
            })
            console.log(code)
            setLoading(false)
            await Share.share({
                message: 'Download the StarPlusBattery app and earn 15 points. User referral code: ' + code
            })
        }
    }
    async function uploadGalleryPhoto(photo, type) {
        setLoading(true)
        let height = photo.height;
        let width = photo.width;
        let expectedHeight = Math.min(1080, height);
        let expectedWidth = (width / height) * expectedHeight;
        let image = await manipulateAsync(photo.uri, [{
            resize: {
                width: expectedWidth,
                height: expectedHeight
            }
        }], {
            compress: 0.75,
            base64: true
        })
        let storageRef = ref(storage, type + '/' + uuidv4() + ".jpg");
        let f = await fetch(image.uri)
        let blob = await f.blob()
        let snapshot = await uploadBytes(storageRef, blob)
        dispatch(setPhoto({
            type: type,
            url: snapshot.metadata.fullPath
        }))
        setLoading(false)
    }

    function pickImage(type) {
        ImagePicker.launchImageLibraryAsync({
            quality: 0.75,
            allowsMultipleSelection: false
        }).then(media => {
            uploadGalleryPhoto(media, type)
        }).catch(e => {
            console.log(e)
        })
    }

    function takePicture(type) {
        pickImage(type)
    }
    async function updateProfilePic(pic) {
        setLoading(true);
        try {
            if(!!user.id){
                await setDoc(doc(database, "users", user.id), {
                    ...user,
                    profilePic: pic
                }, {
                    merge: true
                })
                //ToastAndroid.show('Profile updated successfully', ToastAndroid.SHORT)
                Toast.show('Profile updated successfully', {
                    duration: Toast.durations.SHORT
                }) 
            }
            setLoading(false)
        } catch (e) {
            console.error(e);
            setLoading(false)
        }
    }
    React.useEffect(() => {
        console.log("Profile pic updated", store.profilePic)
        if (!!store.profilePic) {
            updateProfilePic(store.profilePic)
        }
    }, [store.profilePic])
    React.useEffect(() => {
        loadUserProfile();
    }, []);
    return (
        <View style={{ flex: 1 }}>
            <ScrollView>
                <View style={{ margin: 16, alignItems: 'center' }}>
                    {store.profilePic ? <MD3.Avatar.Image size={96} source={{ uri: getImageUrl(store.profilePic) }} color='white' /> : <MD3.Avatar.Text size={96} label={user?.fullName?user.fullName[0]:'?'} color='white' />}
                    <MD3.Title>{user.fullName}</MD3.Title>
                    <MD3.Text>{user.emailId}&nbsp;&#9679;&nbsp;{user.username}</MD3.Text>
                </View>
                <View style={{ paddingHorizontal: 16 }}>
                    <MD3.TextInput value={user.address} onChangeText={value => {
                        setUser({
                            ...user,
                            address: value
                        })
                    }} label={'Address'} returnKeyType='done' onSubmitEditing={saveUser} />
                    <MD3.TextInput value={user.altContact} onChangeText={value => {
                        setUser({
                            ...user,
                            altContact: value
                        })
                    }} label={'Alternate contact number'} keyboardType='phone-pad' style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                    <MD3.TextInput value={user.city} onChangeText={value => {
                        setUser({
                            ...user,
                            city: value
                        })
                    }} label={'City'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                    <MD3.TextInput value={user.state} onChangeText={value => {
                        setUser({
                            ...user,
                            state: value
                        })
                    }} label={'State'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                    <MD3.TextInput value={user.zip} onChangeText={value => {
                        setUser({
                            ...user,
                            zip: value
                        })
                    }} label={'Zip Code'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon='camera'
                        onPress={() => takePicture('profile')}>{!!store.profilePic && 'Retake'} Profile Picture</MD3.Button>
                    <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} icon='share'
                        onPress={shareApp}>Share and Refer</MD3.Button>
                </View>
            </ScrollView>
            {loading && <Loader />}
        </View>
    )
}