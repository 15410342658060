import * as React from 'react'
import { Alert, Image, ScrollView, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { SLABS } from '../../utils'

export default function LoyaltyPointSlab() {
    return (
        <ScrollView style={{ padding: 16 }}>
            <MD3.Caption style={{ marginBottom: 8 }}>Payout Structure</MD3.Caption>
            <MD3.Card>
                <View>
                    {/* <View style={{flexDirection:'row', alignItems: 'center', justifyContent: 'space-between', padding: 16}}>
                        <MD3.Subheading>1 Battery Installation</MD3.Subheading>
                        <MD3.Caption>15 Points</MD3.Caption>
                    </View>
                    <MD3.Divider/> */}
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 16 }}>
                        <MD3.Subheading>1 Point</MD3.Subheading>
                        <MD3.Caption>₦25</MD3.Caption>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Caption style={{ marginVertical: 8 }}>Point/Payout Slab</MD3.Caption>
            <MD3.Card>
                <View style={{ flexDirection: 'row', padding: 16 }}>
                    <Image source={require('./silver.png')} style={{ width: 72, height: 72 }} />
                    <View style={{ marginLeft: 16 }}>
                        <MD3.Text style={{ fontWeight: 'bold' }}>Silver</MD3.Text>
                        <View style={{ flex: 1, justifyContent: 'center' }}>
                            <MD3.Text>{SLABS.SILVER.Start} - {SLABS.SILVER.End} Points</MD3.Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>1 Battery Installation&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>{SLABS.SILVER.Multiplier} points</MD3.Text>
                            </View>
                            {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Min Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦250</MD3.Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Max Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦1250</MD3.Text>
                            </View> */}
                        </View>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Card style={{ marginTop: 8 }}>
                <View style={{ flexDirection: 'row', padding: 16 }}>
                    <Image source={require('./gold.png')} style={{ width: 72, height: 72 }} />
                    <View style={{ marginLeft: 16 }}>
                        <MD3.Text style={{ fontWeight: 'bold' }}>Gold</MD3.Text>
                        <View style={{ flex: 1, justifyContent: 'center' }}>

                            <MD3.Text>{SLABS.GOLD.Start} - {SLABS.GOLD.End} Points</MD3.Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>1 Battery Installation&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>{SLABS.GOLD.Multiplier} points</MD3.Text>
                            </View>
                            {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Min Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦1275</MD3.Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Max Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦6250</MD3.Text>
                            </View> */}
                            {/* <MD3.Caption>+ Gift worth ₦25000</MD3.Caption> */}
                        </View>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Card style={{ marginTop: 8 }}>
                <View style={{ flexDirection: 'row', padding: 16 }}>
                    <Image source={require('./diamond.png')} style={{ width: 72, height: 72 }} />
                    <View style={{ marginLeft: 16 }}>
                        <MD3.Text style={{ fontWeight: 'bold' }}>Diamond</MD3.Text>
                        <View style={{ flex: 1, justifyContent: 'center' }}>

                            <MD3.Text>{SLABS.DIAMOND.Start} - {SLABS.DIAMOND.End} Points</MD3.Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>1 Battery Installation&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>{SLABS.DIAMOND.Multiplier} points</MD3.Text>
                            </View>
                            {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Min Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦6275</MD3.Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Max Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦12500</MD3.Text>
                            </View> */}
                            {/* <MD3.Caption>+ Gift worth ₦50000</MD3.Caption> */}
                        </View>
                    </View>
                </View>
            </MD3.Card>
            <MD3.Card style={{ marginTop: 8 }}>
                <View style={{ flexDirection: 'row', padding: 16 }}>
                    <Image source={require('./platinum.png')} style={{ width: 72, height: 72 }} />
                    <View style={{ marginLeft: 16 }}>
                        <MD3.Text style={{ fontWeight: 'bold' }}>Platinum</MD3.Text>
                        <View style={{ flex: 1, justifyContent: 'center' }}>

                            <MD3.Text>{SLABS.PLATINUM.Start} Points and above</MD3.Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>1 Battery Installation&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>{SLABS.PLATINUM.Multiplier} points</MD3.Text>
                            </View>
                            {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Min Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>₦12525</MD3.Text>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <MD3.Text>Max Payout&nbsp;</MD3.Text>
                                <MD3.Text style={{ fontWeight: 'bold' }}>(variable)</MD3.Text>
                            </View> */}
                            {/* <MD3.Caption>+100 points at the end of month</MD3.Caption> */}
                        </View>
                    </View>
                </View>
            </MD3.Card>
        </ScrollView>
    )
}