import * as React from 'react'
import { Alert, ScrollView, Share, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
// import { generate } from 'get-initials';
import { getImageUrl } from '../../utils';
import otpGenerators from 'otp-generators';
import * as Sharing from 'expo-sharing';
import Toast from 'react-native-root-toast';

export default function () {
    let [loading, setLoading] = React.useState(false)
    let [user, setUser] = React.useState({})
    let [bank, setBank] = React.useState({
        bankName: '',
        acNo: ''
    })

    async function loadProfileDetails() {
        setLoading(true)
        let _user = await AsyncStorage.getItem('user')
        _user = JSON.parse(_user)
        setUser(_user)
        let result = await getDoc(doc(database, "bank_accounts", _user.id))
        if (result.exists()) {
            console.log("Bank account exists")
            console.log(result.data())
            setBank({
                ...result.data()
            })
        } else {
            console.log("Bank account does not exists")
        }
        setLoading(false)
    }
    async function saveBankAcDetails() {
        setLoading(true)
        console.log("Setting bank account", bank, "for", user.id)
        await setDoc(doc(database, "bank_accounts", user.id), bank)
        alert('Bank account updated successfully')
        setLoading(false)
    }
    React.useEffect(() => {
        loadProfileDetails()
    }, [])
    return (
        <View style={{ flex: 1 }}>
            <ScrollView style={{ padding: 16, flexGrow: 1 }}>
                <View>
                    <View style={{ padding: 16 }}>
                        <MD3.Text style={{ color: 'red', backgroundColor: 'yellow', borderRadius: 6, padding: 8, textAlign: 'center', fontWeight: 'bold' }}>Star Plus will not be responsible for wrong OR inaccurate bank information provided</MD3.Text>
                    </View>

                    <MD3.Caption style={{ fontWeight: 'bold', marginBottom: 8 }}>Bank Account Details</MD3.Caption>
                    <MD3.TextInput value={bank.bankName} onChangeText={value => {
                        setBank({
                            ...bank,
                            bankName: value
                        })
                    }} label={'Bank name'} style={{ marginBottom: 8 }} />
                    <MD3.TextInput value={bank.acNo} onChangeText={value => {
                        setBank({
                            ...bank,
                            acNo: value
                        })
                    }} label={'Account Number'} style={{ marginBottom: 8 }} />

                    <MD3.Caption>Account Type</MD3.Caption>
                    <select style={{
                        height: 54,
                        paddingLeft: 16,
                        paddingRight: 16
                    }} value={bank.accountType} onChange={evt => {
                        setBank({
                            ...bank,
                            accountType: evt.target.value
                        })
                    }}>
                        <option value={'Savings Account'}>Savings Account</option>
                        <option value={'Current Account'}>Current Account</option>
                    </select>

                </View>
            </ScrollView>
            <MD3.Button onPress={saveBankAcDetails} mode='contained' style={{ marginBottom: 16, marginHorizontal: 16 }} loading={loading} disabled={loading || !bank.acNo || !bank.bankName}>Save</MD3.Button>
            {loading && <Loader />}
        </View>
    )
}
