import * as React from 'react'
import { FlatList, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { useState } from 'react';
import { addDays, endOfDay, format, isAfter, isBefore, isEqual, parse, startOfDay, subDays } from 'date-fns';
import Loader from '../../loader';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomers, setLga } from '../../defaultSlice';
import { collection, getDocs, query, Timestamp, where } from 'firebase/firestore';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { database } from '../../firebase-app';
import { Octicons } from '@expo/vector-icons';

export default function MyWallet() {
    let [startDate, setStartDate] = useState(new Date())
    let [endDate, setEndDate] = useState(new Date())
    let [showStartDate, setShowStartDate] = useState(false)
    let [showEndDate, setShowEndDate] = useState(false)
    let [loading, setLoading] = useState(false)
    let [user, setUser] = useState({})
    let [customers, setCustomers] = useState([])
    let navigation = useNavigation()
    let state = useSelector(state => state.default)
    let dispatch = useDispatch()
    let [approvedSelected, setApprovedSelected] = useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = useState(true)
    async function getUser() {
        let _user = await AsyncStorage.getItem('user')
        setUser(JSON.parse(_user))
    }
    async function generateReport() {
        console.log(user.id, "Approved", `${state.selectedState}, ${state.selectedLga}`)
        setLoading(true)
        let reports = {};
        if (!!state.selectedLga) {
            reports = await getDocs(query(collection(database, "customers"),
                where("user", "==", user.id),
                where("lga", "==", `${state.selectedState}, ${state.selectedLga}`)))
        } else {
            reports = await getDocs(query(collection(database, "customers"),
                where("user", "==", user.id)))
        }
        setCustomers(reports.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }))
        setLoading(false)
    }
    function selectLGA() {
        dispatch(setLga(null))
        navigation.navigate('select-state')
    }
    function dateFromSeconds(date) {
        return format(parse(date, 'y-MM-dd', new Date()), 'd MMM, y')
    }
    let filteredCustomers = customers.filter(e => {
        let iDate = parse(e.installationDate, 'y-MM-dd', new Date())
        let sDate = startOfDay(startDate)
        let eDate = endOfDay(endDate)
        return (
            (isAfter(iDate, sDate) || isEqual(iDate, sDate))
            &&
            (isBefore(iDate, eDate) || isEqual(iDate, eDate))
        )
    }).sort((a, b) => {
        return isAfter(parse(a.installationDate, 'y-MM-dd', new Date()), parse(b.installationDate, 'y-MM-dd', new Date()))?-1:1
    })
    function openCustomer(customer) {
        navigation.navigate('view-customer', {
            customer
        })
    }
    useEffect(() => {
        getUser()
    }, [])
    return (
        <View style={{ flex: 1 }}>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', padding: 16 }}>
                <MD3.Card style={{ padding: 16, flexGrow: 1, marginRight: 8 }}>
                    <MD3.Caption>Start Date</MD3.Caption>
                    {/* <MD3.Text>{format(startDate, 'dd MMM, y')}</MD3.Text> */}
                    <input type="date" value={format(startDate, 'y-MM-dd')} onChange={evt => {
                        let dateStr = evt.target.value;
                        let date = new Date(dateStr)
                        if (!!date) {
                            if (isAfter(date, endDate)) {
                                setEndDate(addDays(date, 1))
                            } else {
                                console.log("End date", endDate, "is after start")
                            }
                            setStartDate(date)
                        }
                    }}/>
                </MD3.Card>

                <MD3.Card style={{ padding: 16, flexGrow: 1, marginLeft: 8 }} onPress={() => setShowEndDate(true)}>
                    <MD3.Caption>End Date</MD3.Caption>
                    {/* <MD3.Text>{format(endDate, 'dd MMM, y')}</MD3.Text> */}
                    <input type="date" value={format(endDate, 'y-MM-dd')} onChange={evt => {
                        let dateStr = evt.target.value;
                        let date = new Date(dateStr)
                        if (!!date) {
                            if (isBefore(date, startDate)) {
                                setStartDate(subDays(date, 1))
                            }
                            setEndDate(date)
                        }
                    }}/>
                </MD3.Card>
            </View>

            <MD3.Card style={{ marginHorizontal: 16, marginBottom: 16, padding: 16 }} onPress={() => {
                selectLGA()
            }}>
                <MD3.Caption>Select LGA</MD3.Caption>
                {(state.selectedState && state.selectedLga) && <MD3.Text>{(state.selectedState && state.selectedLga) && `${state.selectedState}, ${state.selectedLga}`}</MD3.Text>}
                {!(state.selectedState && state.selectedLga) && <MD3.Text>All</MD3.Text>}
            </MD3.Card>

            <MD3.Button disabled={loading} onPress={generateReport} mode='contained' style={{ marginHorizontal: 16, marginBottom: 8 }}>Generate Report</MD3.Button>

            <MD3.Caption style={{ marginBottom: 16, paddingHorizontal: 16, fontWeight: 'bold' }}>REPORT</MD3.Caption>

            {filteredCustomers.length> 0 && <View style={{ flexDirection: 'row', marginBottom: 8, marginHorizontal: 16 }}>
                <MD3.Chip selected={approvedSelected} onPress={() => setApprovedSelected(!approvedSelected)} style={{ flexGrow: 1 }}>Approved ({filteredCustomers.filter(e => e.status === 'Approved').length})</MD3.Chip>
                <View style={{ width: 16 }} />
                <MD3.Chip selected={pendingApprovalSelected} onPress={() => setPendingApprovalSelected(!pendingApprovalSelected)} style={{ flexGrow: 1 }}>Pending approval ({filteredCustomers.filter(e => e.status !== 'Approved').length})</MD3.Chip>
            </View>}

            <FlatList
                data={filteredCustomers.filter(e => {
                    if(pendingApprovalSelected && !approvedSelected){
                        return e.status !== 'Approved'
                    }else if(!pendingApprovalSelected && !approvedSelected){
                        return false;
                    }else if(pendingApprovalSelected && approvedSelected) {
                        return true;
                    } else if(!pendingApprovalSelected && approvedSelected){
                        return e.status === 'Approved'
                    }
                })}
                ItemSeparatorComponent={() => <MD3.Divider />}
                ListEmptyComponent={() => {
                    return <MD3.Caption style={{ marginLeft: 16 }}>No customers found with given criteria</MD3.Caption>
                }}
                keyExtractor={(item, index) => `report-${index}`}
                renderItem={({ item }) => {
                    return <MD3.Card style={{ padding: 16, marginTop: 8, marginHorizontal: 16 }} onPress={() => {
                        openCustomer(item)
                    }}>
                        <MD3.Caption>Date</MD3.Caption>
                        <MD3.Text>{dateFromSeconds(item.installationDate)}</MD3.Text>
                        <MD3.Caption>Customer Name</MD3.Caption>
                        <MD3.Text>{item.fullName}</MD3.Text>
                        <MD3.Caption>Customer Address</MD3.Caption>
                        <MD3.Text>{item.fullAddress}</MD3.Text>
                        <MD3.Text>{item.lga}</MD3.Text>
                        
                        <Octicons style={{position: 'absolute', right: 0, top: 0}} name="dot-fill" size={24} color={item.status==='Pending'?MD3.MD2Colors.amberA700:MD3.MD2Colors.greenA700} />
                    </MD3.Card>
                }}
            />

            {showStartDate && <RNDateTimePicker maximumDate={subDays(new Date(), 1)} mode="date" value={startDate} onChange={(evt, date) => {
                setShowStartDate(false)
                if (!!date) {
                    if (isAfter(date, endDate)) {
                        setEndDate(addDays(date, 1))
                    } else {
                        console.log("End date", endDate, "is after start")
                    }
                    setStartDate(date)
                }
            }} />}
            {showEndDate && <RNDateTimePicker maximumDate={new Date()} mode="date" value={endDate} onChange={(evt, date) => {
                setShowEndDate(false)
                if (!!date) {
                    if (isBefore(date, startDate)) {
                        setStartDate(subDays(date, 1))
                    }
                    setEndDate(date)
                }
            }} />}

            {loading && <Loader />}
        </View>
    )
}