import * as React from 'react'
import { Alert, Linking, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setLga } from '../../defaultSlice';
import { useDispatch } from 'react-redux';
import Loader from '../../loader';
import { deleteDoc, getDoc, doc } from 'firebase/firestore';
import { database } from '../../firebase-app';

export default function TechnicianProfile() {
    let navigation = useNavigation()
    let dispatch = useDispatch();
    let [loading, setLoading] = React.useState(false)
    async function logout(){
        await AsyncStorage.clear();
        navigation.replace('login')
    }
    function openFeedback(){
        navigation.navigate('feedback')
    }
    async function deleteAccount() {
        if(confirm('Do you want to delete your account?')){
            try{
                setLoading(true)
                let _user = await AsyncStorage.getItem('user')
                let user = JSON.parse(_user)
                console.log(user.id)
                await deleteDoc(doc(database, "users", user.id))
                await AsyncStorage.clear();
                alert("Your account is deleted.")
                navigation.replace('login')
            }catch(e){
                
            }finally {
                setLoading(false)
            }
        }
        
    }
    return (
            <ScrollView style={{padding: 16, flex: 1}} showsVerticalScrollIndicator={false}>
                <MD3.Caption>ACCOUNT</MD3.Caption>
                <View style={{height: 16}}/>
                <MD3.List.Item onPress={() =>{
                    navigation.navigate('edit-profile')
                }} title='Profile Details' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    dispatch(setLga(null))
                    navigation.navigate('my-wallet')
                }} title='Reports' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'seal'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    navigation.navigate('redeem-points')
                }} title='Redeem Loyalty Points' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'currency-ngn'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    navigation.navigate('point-audit-technician')
                }} title='Points Audit Report' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-cash-outline'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    navigation.navigate('referral-report')
                }} title='Referral report' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-group-outline'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    navigation.navigate('loyalty-point-slab')
                }} title='Loyalty Points Slab' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'podium-gold'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item onPress={() => {
                    navigation.navigate('bank')
                }} title='Bank Account Details' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'bank'} {...props}/>}/>
                <MD3.Divider/>
                
                <View style={{height: 16}}/>
                <MD3.Caption>POLICIES</MD3.Caption>
                <View style={{height: 16}}/>
                <MD3.List.Item title='Frequently Asked Questions' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'help'} {...props}/>} onPress={() => {
                    Linking.openURL('https://www.keepandshare.com/doc10/35722/faq-pdf-114k?da=y')
                }}/>
                <MD3.Divider/>
                <MD3.List.Item title='Terms & Conditions' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'scale-balance'} {...props}/>} onPress={() => {
                    Linking.openURL('https://www.keepandshare.com/doc10/35725/terms-conditions-pdf-164k?da=y')
                }}/>
                <MD3.Divider/>
                <MD3.List.Item title='Privacy Policy' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props}/>} onPress={() => {
                    Linking.openURL('https://www.keepandshare.com/doc10/35724/privacy-policy-pdf-110k?da=y')
                }}/>
                <MD3.Divider/>
                <MD3.List.Item title='Contact Us' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'phone'} {...props}/>} onPress={() => {
                    Linking.openURL('https://www.keepandshare.com/doc10/35723/contact-us-pdf-49k?da=y')
                }}/>
                <MD3.List.Item title='Submit Feedback' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'message-alert'} {...props}/>} onPress={() => {
                    openFeedback();
                }}/>
                <MD3.Divider/>
                <View style={{height: 16}}/>
                <MD3.Caption>LOGINS</MD3.Caption>
                <View style={{height: 16}}/>
                <MD3.List.Item title='Logout' onPress={() => {
                    logout();
                }} style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props}/>}/>
                <MD3.Divider/>
                <MD3.List.Item title='Delete Account' style={{backgroundColor: 'white'}} titleStyle={{color: MD3.MD2Colors.redA700}} left={(props) => <MD3.List.Icon icon={'delete-forever'} {...props} color={MD3.MD2Colors.redA700}/>} onPress={() => {
                    deleteAccount();
                }}/>
                <View style={{height: 24}}/>
            </ScrollView>
            // {loading && <Loader/>}
    )
}