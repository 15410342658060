import { useNavigation } from '@react-navigation/native'
import * as React from 'react'
import { Image, TouchableOpacity, useWindowDimensions, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { database } from '../firebase-app'
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import ResetPassword from './ResetPassword'
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { useDispatch } from 'react-redux'
import { resetStore } from '../defaultSlice'
import Toast from 'react-native-root-toast';
import ToastIOS from '../ToastIOS'

const userRef = collection(database, "users");


export default function LoginScreen() {
    let dimension = useWindowDimensions()
    let navigation = useNavigation();
    let [loginVisible, setLoginVisible] = React.useState(false)
    let [resetVisible, setResetVisible] = React.useState(false)
    let [loading, setLoading] = React.useState(false)
    let [loginType, setLoginType] = React.useState('Technician')
    let [username, setUsername] = React.useState('')
    let [password, setPassword] = React.useState('')
    let [rPassStatus, setResetPasswordStatus] = React.useState('fresh')
    let [secureTextEntry, setSecureTextEntry] = React.useState(true)
    let ref1 = React.useRef();
    let dispatch = useDispatch();
    function hideDialog() {
        setUsername('')
        setPassword('')
        setLoginVisible(false)
    }
    function hideResetDialog() {
        setResetVisible(false)
    }

    function loginAsAdmin() {
        setLoginType('Administrator')
        setLoginVisible(true)
    }
    function loginAsTechnician() {
        setLoginType('Technician')
        setLoginVisible(true)
    }
    async function navigateToDashboard(id, userType) {
        setLoading(true)
        let token = await AsyncStorage.getItem('token')
        if (!!token) {
            await setDoc(doc(database, "users", id), {
                token: token
            }, {
                merge: true
            })
        }
        setLoading(false)
        if (userType == "Administrator") {
            navigation.replace('admin-dashboard')
        } else {
            navigation.replace('tenician-dashboard')
        }
    }
    async function checkSession() {
        let userData = await AsyncStorage.getItem('user')
        if (!!userData) {
            let user = JSON.parse(userData)
            if (!!user?.id) {
                let userType = user.type;
                console.log("Logged in as " + userType)
                navigateToDashboard(user?.id, userType);
            }
        }
    }
    React.useEffect(() => {
        checkSession();
    }, [])
    function registerAsTechnician() {
        navigation.navigate('member-registration')
    }
    function goToResetPassword() {
        hideDialog();
        setResetVisible(true);
    }
    async function onLogin() {
        dispatch(resetStore())
        if (username.includes(" ")) {
            alert("Do not add space in username")
            return;
        }
        setLoading(true)
        try {
            let records = await getDocs(query(userRef, where("username", "==", username.toLowerCase()), where('password', '==', password)))
            if (records.docs.length == 0) {
                records = await getDocs(query(userRef, where("emailId", "==", username.toLowerCase()), where('password', '==', password)))
            }
            if (records.docs.length == 0) {
                //ToastAndroid.show("Invalid login credentials", ToastAndroid.SHORT)
                Toast.show('Invalid login credentials', {
                    duration: Toast.durations.SHORT
                }) 
            } else {
                let doc = records.docs[0].data()
                if (doc.type === loginType && !doc.disabled) {
                    let user = {
                        ...doc,
                        id: records.docs[0].id
                    }
                    setLoading(false)
                    hideDialog();
                    if(!!doc.disabled){
                        Toast.show('Your account is disabled', {
                            duration: Toast.durations.SHORT
                        }) 
                        return;
                    }
                    await AsyncStorage.setItem('user', JSON.stringify(user))
                    navigateToDashboard(records.docs[0].id, user.type)
                } else {
                    //ToastAndroid.show("Invalid login credentials", ToastAndroid.SHORT)
                    Toast.show('Invalid login credentials. Or your account might have been disabled', {
                        duration: Toast.durations.SHORT
                    }) 
                }
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }
    return (
        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 0.67 }}>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Image source={require('../assets/star-plus-logo.png')} style={{ width: Math.min(320, dimension.width * 85), height: 180 }} resizeMode='contain' />
                <MD3.Headline style={{ textAlign: 'center', textTransform: 'capitalize' }}>Hello, Welcome to{"\n"}Star Plus Battery{"\n"}loyalty reward program</MD3.Headline>
            </View>
            <View style={{ alignItems: 'center', margin: 16 }}>
                <MD3.Button mode='contained' style={{ width: 200 }} onPress={loginAsTechnician}>Login</MD3.Button>
                <View style={{ flexDirection: 'row' }}>
                    {/* <MD3.Button mode='text' style={{ marginTop: 8 }} onPress={loginAsAdmin}>Login as admin</MD3.Button> */}
                    <MD3.Button mode='outlined' style={{ marginTop: 8, width: 200 }} textColor={MD3.MD3Colors.primary10} onPress={registerAsTechnician}>New Registration</MD3.Button>
                </View>
                {/* <MD3.Caption>App v0.51</MD3.Caption> */}
            </View>
            <MD3.Dialog visible={loginVisible} onDismiss={hideDialog} dismissable={false} style={{ backgroundColor: 'white', width: 480, marginStart: 'auto', marginEnd: 'auto' }}>
                <MD3.Dialog.Title>Login as {loginType}</MD3.Dialog.Title>
                <MD3.Dialog.Content>
                    <MD3.TextInput style={{textTransform: 'lowercase'}} keyboardType='name-phone-pad' onChangeText={setUsername} disabled={loading} label={'Enter Email/Mobile No'} mode='outlined' returnKeyType='next' onSubmitEditing={() => ref1.current?.focus()} blurOnSubmit={false} />
                    <MD3.TextInput defaultValue={password} onChangeText={setPassword} disabled={loading} label={'Enter password'} mode='outlined' secureTextEntry={secureTextEntry} ref={ref1} style={{ marginTop: 8 }}
                        right={<MD3.TextInput.Icon icon={secureTextEntry?'eye-off':'eye'} onPress={() => setSecureTextEntry(!secureTextEntry)} />} />

                    <TouchableOpacity style={{ justifyContent: 'center', flexDirection: 'row', marginTop: 8, marginBottom: -8 }}>
                        <MD3.Text style={{ color: 'blue' }} onPress={goToResetPassword}>Reset password</MD3.Text>
                    </TouchableOpacity>
                </MD3.Dialog.Content>
                <MD3.Dialog.Actions>
                    <MD3.Button disabled={loading} onPress={hideDialog}>&nbsp;Dismiss&nbsp;</MD3.Button>
                    <MD3.Button onPress={() => {
                        onLogin();
                    }} mode='contained' icon='lock' disabled={loading || !password} loading={loading} labelStyle={{ marginHorizontal: 16 }}>&nbsp;Login&nbsp;</MD3.Button>
                </MD3.Dialog.Actions>
            </MD3.Dialog>
            {/* <MD3.Button mode='text' style={{ marginTop: 16, position: 'absolute', right: 8, top: 16}} onPress={loginAsAdmin}>Admin</MD3.Button> */}
            <ResetPassword hideResetDialog={hideResetDialog} resetVisible={resetVisible} />
        </View>
    )
}
