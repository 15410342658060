import * as React from 'react'
import { View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TechnicianHome from './technician/t-home';
import { AntDesign } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import CustomersList from './technician/customers';
import TechnicianProfile from './technician/profile';

const Tab = createBottomTabNavigator();

export default function TechnicianDashboard() {
    return (
        <Tab.Navigator initialRouteName='Home'>
            <Tab.Screen name="Customer" options={{unmountOnBlur: true, headerShown: false, tabBarIcon: (props) => <MaterialCommunityIcons name="car-battery" {...props} />}} component={CustomersList} />
            <Tab.Screen name="Home" options={{tabBarIcon: (props) => <AntDesign name="home" {...props} />}} component={TechnicianHome} />
            <Tab.Screen name="Profile" options={{tabBarIcon: (props) => <AntDesign name="user" {...props} />}} component={TechnicianProfile} />
        </Tab.Navigator>
    )
}