import * as React from 'react'
import { Linking, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { AntDesign } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setLga } from '../../defaultSlice';
import { useDispatch } from 'react-redux';

export default function AdminReportPage() {
    let navigation = useNavigation()
    let dispatch = useDispatch();
    async function logout(){
        await AsyncStorage.clear();
        navigation.replace('login')
    }
    function openFeedback(){
        navigation.navigate('feedback')
    }
    return (
        <View style={{padding: 16}} showsVerticalScrollIndicator={false}>
            <MD3.Caption>REPORTS</MD3.Caption>
            <View style={{height: 16}}/>
            <MD3.List.Item onPress={() =>{
                navigation.navigate('admin-report')
            }} title='Customer Report' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'account-outline'} {...props}/>}/>
            <MD3.Divider/>
            <MD3.List.Item onPress={() =>{
                navigation.navigate('admin-points-report')
            }} title='Points Report' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'seal'} {...props}/>}/>
            <MD3.Divider/>
            <MD3.List.Item onPress={() => {
                //dispatch(setLga(null))
                navigation.navigate('admin-area-report')
            }} title='Area Wise Report' style={{backgroundColor: 'white'}} left={(props) => <MD3.List.Icon icon={'map-marker-multiple-outline'} {...props}/>}/>
            <MD3.Divider/>
        </View>
    )
}