import * as React from 'react'
import { Alert, ScrollView, Share, ToastAndroid, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
import { getImageUrl } from '../../utils';
import * as utils from '../../utils'
import otpGenerators from 'otp-generators';
import * as Sharing from 'expo-sharing';
import axios from 'axios';
import Toast from 'react-native-root-toast';
const storage = getStorage();


export default function ViewTechnician() {
    let [user, setUser] = React.useState({})
    let [loading, setLoading] = React.useState(false)
    let store = useSelector(state => state.default)
    let navigation = useNavigation()
    let route = useRoute();
    let dispatch = useDispatch();
    async function loadUserProfile() {
        console.log("*************")
        console.log(route.params.technician)
        setUser({
            ...route.params.technician
        })
    }
    async function saveUser() {
        setLoading(true);
        await setDoc(doc(database, "users", user.id), {
            ...user
        }, {
            merge: true
        })
        setLoading(false)
        //ToastAndroid.show('Profile updated successfully', ToastAndroid.SHORT)
        Toast.show('Profile updated successfully', {
            duration: Toast.durations.SHORT
        }) 
    }

    async function approveProfile() {
        setLoading(true);
        let newUserData = {
            ...user,
            status: 'Approved'
        }
        if (!!user.referralCode) {
            let _data = await getDocs(query(collection(database, "users"), where("referral", "==", user.referralCode)))
            if (_data.size >= 1) {
                let parent = _data.docs[0];
                let parentData = parent.data();
                await setDoc(doc(database, "users", parent.id), {
                    ...parentData,
                    points: +(parentData.points || 0) + 25,
                    totalPoints: +(parentData.totalPoints || 0) + 25
                }, {
                    merge: true
                })
                await utils.AUDITORS.auditPoints(parentData, 25, 'New referral ('+ newUserData.fullName +')', {'technician_name': newUserData.fullName})
                await setDoc(doc(database, "users", user.id), {
                    ...newUserData,
                    points: 15
                }, {
                    merge: true
                })
                utils.AUDITORS.auditPoints(newUserData, 15, 'Joining bonus referral (Referred by '+ parentData.fullName +' )', {})
                let adminToken = await utils.getAdminToken()
                if (!!parentData.token) {
                    try {
                        utils.sendPush(parentData.token, "Your referral " + user.fullName + " has been approved!", "Approved referral!")
                    } catch (e) {
                        console.log("P1 Failed", e)
                    }
                }
                if (!!user.token) {
                    try {
                        utils.sendPush(user.token, "Your account has been approved!", "Account Approved!")
                    } catch (e) {
                        console.log("P2 Failed", e)
                    }
                }
                try {
                    await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                        "to": parentData.emailId,
                        "subject": "Star Plus Battery : New Referral",
                        "message": "Congratulations! A new member has joined the Loyalty program using your referral code"
                    })
                } catch (e) {
                    console.log("A1 Failed", e)
                }
            }else{
                await setDoc(doc(database, "users", user.id), {
                    ...newUserData
                }, {
                    merge: true
                })
                if (!!user.token) {
                    try {
                        utils.sendPush(user.token, "Your account has been approved!", "Account Approved!")
                    } catch (e) {
                        console.log("P2 Failed", e)
                    }
                }
            }
        } else {
            await setDoc(doc(database, "users", user.id), {
                ...newUserData
            }, {
                merge: true
            })
        }
        try {
            let res1 = await axios.post('https://asia-south1-star-plus-battery.cloudfunctions.net/mail-util', {
                "to": user.emailId,
                "subject": "Star Plus Battery : Approval",
                "message": "Congratulations! Your profile has been approved! Please collect your cap and T-shirt from Admin office.<br/>You can now start installing batteries at customer site and earn loyalty points.<br/>"
            })
            console.log(res1.data)
        } catch (e) {
            console.log("A2 Failed", e)
        }
        setLoading(false)
        //ToastAndroid.show('Approved successfully!', ToastAndroid.SHORT)
        Toast.show('Approved successfully', {
            duration: Toast.durations.SHORT
        }) 
        navigation.pop(2)
    }
    React.useEffect(() => {
        loadUserProfile();
    }, []);
    return (
        <View style={{ flex: 1 }}>
            <View style={{ margin: 16, alignItems: 'center' }}>
                {/* {user.profilePic ? <MD3.Avatar.Image size={96} source={{ uri: getImageUrl(user.profilePic) }} color='white' /> : <MD3.Avatar.Text size={96} label={generate(user.fullName)} color='white' />} */}
                <MD3.Title>{user.fullName}</MD3.Title>
                <MD3.Text>{user.emailId}&nbsp;&#9679;&nbsp;{user.username}</MD3.Text>
            </View>
            <View style={{ paddingHorizontal: 16 }}>
                <MD3.TextInput editable={false} value={user.address} onChangeText={value => {
                    setUser({
                        ...user,
                        address: value
                    })
                }} label={'Address'} returnKeyType='done' onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.altContact} onChangeText={value => {
                    setUser({
                        ...user,
                        altContact: value
                    })
                }} label={'Alternate contact number'} keyboardType='phone-pad' style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.city} onChangeText={value => {
                    setUser({
                        ...user,
                        city: value
                    })
                }} label={'City'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.state} label={'State'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.TextInput editable={false} value={user.zip} label={'Zip Code'} style={{ marginTop: 8 }} onSubmitEditing={saveUser} />
                <MD3.Button mode='outlined' style={{ borderRadius: 4, marginTop: 16 }} textColor='green' icon='shield' disabled={loading || user.status === 'Approved'}
                    onPress={approveProfile}> Approve</MD3.Button>
            </View>
            {loading && <Loader />}
        </View>
    )
}