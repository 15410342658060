import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as react from 'react'
import { FlatList, Image, ScrollView, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { database } from '../../firebase-app';
import Loader from '../../loader'
import { useDispatch, useSelector } from 'react-redux';
import { setLga, setPhoto, setState, setProducts as setStoreProducts, setDefaultProduct, setSelectedProduct } from '../../defaultSlice';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Octicons } from '@expo/vector-icons';
import { getImageUrl } from '../../utils';


export default function ListProducts() {
    let [products, setProducts] = react.useState([])
    let navigation = useNavigation();
    let [loading, setLoading] = react.useState(false)
    let dispatcher = useDispatch();
    let store = useSelector(state => state.default)
    let [approvedSelected, setApprovedSelected] = react.useState(true)
    let [pendingApprovalSelected, setPendingApprovalSelected] = react.useState(true)
    function renderProduct({ item }) {
        return (
            <MD3.Card style={{ padding: 16, marginTop: 8 }} onPress={() => viewProduct(item)}>
                <View>
                    <MD3.Caption>Product Code</MD3.Caption>
                    <MD3.Text>{item.code}</MD3.Text>
                </View>
            </MD3.Card>
        )
    }
    async function loadProducts() {
        setLoading(true)
        let docs = await getDocs(query(collection(database, "default_products")))
        let _docs = [];
        docs.docs.forEach(doc => {
            let data = doc.data();
            _docs.push({
                ...data,
                id: doc.id
            })
        })
        dispatcher(setStoreProducts(_docs))
        setLoading(false)
    }
    function viewProduct(item) {
        dispatcher(setSelectedProduct(item.code))
        navigation.goBack();
    }
    useFocusEffect(() => {
        console.log("useFocusEffect")
    })
    react.useEffect(() => {
        loadProducts();
    }, [])
    return (
        <View style={{ flex: 1 }}>
            <FlatList
                data={[<View style={{ flex: 1, padding: 16 }}>
                    <FlatList
                        data={store.products}
                        renderItem={renderProduct}
                        ListEmptyComponent={() => (
                            <MD3.Caption style={{}}>No products available</MD3.Caption>
                        )}
                        keyExtractor={item => `customer-${item.id}`} />
                </View>]}
                renderItem={({ item }) => item}
                keyExtractor={(item, idx) => `dataitem-${idx}`}
            />
            {loading && <Loader />}
        </View>
    )
}