import * as React from 'react'
import { Alert, FlatList, ScrollView, Share, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation } from '@react-navigation/native';
import DateTimePicker from '@react-native-community/datetimepicker';
import { uuidv4 } from '@firebase/util';
import { database } from '../../firebase-app';
import { doc, setDoc, getDoc, query, getDocs, collection, where, orderBy } from 'firebase/firestore';
import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Camera } from 'expo-camera';
import RNDateTimePicker from '@react-native-community/datetimepicker';
import { format } from 'date-fns';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImagePicker from 'expo-image-picker'
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { manipulateAsync } from 'expo-image-manipulator';
import { setPhoto } from '../../defaultSlice';
import Loader from '../../loader';
// import { generate } from 'get-initials';
import { getImageUrl } from '../../utils';
import otpGenerators from 'otp-generators';
import * as Sharing from 'expo-sharing';
import Toast from 'react-native-root-toast';


export default function() {
    let [loading, setLoading] = React.useState(false)
    let [user, setUser] = React.useState({})
    let [audit, setAudit] = React.useState([])
    async function loadProfileDetails() {
        setLoading(true)
        let _user = await AsyncStorage.getItem('user')
        _user = JSON.parse(_user)
        setUser(_user)
        let result = await getDocs(query(collection(database, "points_audit"), where("user.id", "==", _user.id), orderBy("timestamp", "desc")))
        console.log(result.size)
        setAudit(result.docs.map(e => {
            return {
                ...e.data(),
                id: e.id
            }
        }))
        setLoading(false)
    }
    React.useEffect(() => {
        loadProfileDetails()
    }, [])
    return (
        <View
            style={{flex: 1}}>
            <FlatList
                style={{flex: 1}}
                data={audit}
                keyExtractor={item => item.id}
                ListHeaderComponent={() => {
                    return <View style={{height: 16}}/>
                }}
                ListFooterComponent={() => {
                    return <View style={{height: 16}}/>
                }}
                ItemSeparatorComponent={() => {
                    return <View style={{height: 16}}/>
                }}
                renderItem={({item}) => {
                    return (
                        <MD3.Card style={{padding: 16, marginHorizontal: 16}}>
                            <View>
                                <MD3.Text>Points(CR/DR): {item.points}</MD3.Text>
                                <MD3.Text>Reason: {item.reason}</MD3.Text>
                                <MD3.Text>Date: {item.timestamp_str}</MD3.Text>
                            </View>
                        </MD3.Card>
                    )
                }}/>
            {loading && <Loader/>}
        </View>
    )
}