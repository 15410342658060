import * as React from 'react'
import { Image, useWindowDimensions, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import RedeemDashboard from './redeem-dashboard';
import RedeemPoints from './redeem-points';

export default function TechnicianHome() {
    let dimension = useWindowDimensions();
    return (
        <View style={{flexDirection: 'column', flex:1}}>
            {/* <Image source={require('../../assets/star-plus-logo.png')} style={{width: dimension.width*.75, height: 256}} resizeMode='contain'/> */}
            <RedeemDashboard/>
        </View>
    )
}