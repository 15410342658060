import * as React from 'react'
import { FlatList, ScrollView, ToastAndroid, TouchableOpacity, View } from 'react-native'
import * as MD3 from 'react-native-paper'
import { useNavigation, useRoute } from '@react-navigation/native';
import  {states} from './states.js'
import { useDispatch } from 'react-redux';
import { setLga, setState } from '../../defaultSlice.js';

export default function SelectLGAValue() {
  let route = useRoute();
  let navigation = useNavigation();
  let lgas = states[route.params.state]
  let dispatcher = useDispatch();
  function selectLga(item){
    dispatcher(setState(route.params.state))
    dispatcher(setLga(item))
    navigation.pop(2)
  } 
  return (
    <View>
      <FlatList
        data={lgas}
        ItemSeparatorComponent={() => <MD3.Divider />}
        renderItem={({ item, index }) => {
          return <TouchableOpacity style={{ backgroundColor: 'white', padding: 16 }} onPress={() => {
            selectLga(item)
          }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <MD3.Text>{item}</MD3.Text>
            </View>
          </TouchableOpacity>
        }}
        keyExtractor={(item, index) => `lga-${index}`} />
    </View>
  )
}
