const { default: AsyncStorage } = require("@react-native-async-storage/async-storage")
import { setDoc, doc } from 'firebase/firestore';
import { database } from './firebase-app';
import { format } from 'date-fns';
const { default: axios } = require("axios")
const prefixes = ["701", "7020", "7025", "7026", "7027", "7028", "7029", "703", "704", "705", "706", "707", "708", "709", "802", "803", "803", "804", "805", "806", "807", "808", "809", "810", "811", "812", "813", "814", "815", "816", "817", "818", "819", "909", "908", "901", "902", "903", "904", "905", "906", "907", "915", "913", "912", "916"];
export function getImageUrl(id) {
    console.log("Image url for", id)
    return `https://firebasestorage.googleapis.com/v0/b/star-plus-battery.appspot.com/o/${encodeURIComponent(id)}?alt=media`
} 
export async function getAdminToken() {
    return await AsyncStorage.getItem('admin_token')
} 
export const ADMIN_EMAIL = 'support@starplus.com.ng';
export function sendPush(to, message, title) {
    axios.post('https://sumantab9-eval-prod.apigee.net/expo-push', [
        {
            "to": to,
            "title": title,
            "body": message
        }
    ]).catch(ex => {
        console.log(ex)
    }).then(() => {
        console.log("Axios complete")
    })
    return null;
}
export function showToast(Toast, message) {
    let toast = Toast.show(message, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
        shadow: true,
        animation: true,
        hideOnPress: true,
        delay: 0
    });
    setTimeout(function () {
        Toast.hide(toast);
    }, 1500);
}
export function validPhoneNumber(num) {
    if (num === "958") {
        return true;
    }
    if (!num || num.length != 10) {
        return false;
    }
    if (num == "00000000" || num == "111111000" || num.startsWith("0180")) {
        return false;
    }
    return !prefixes.map(e => {
        return num.startsWith(e)
    }).every(e => !e)
}
export const AUDITORS = {
    auditPoints: async (user, points, reason, extra) => {
        let auditId = ("AUD" + Math.random()).replace(".", "");
        await setDoc(doc(database, "points_audit", auditId), {
            user: user,
            points: points,
            reason: reason,
            timestamp: new Date(),
            timestamp_str: format(new Date(), 'd MMM y, h:mm a'),
            ...extra
        })
        console.log("Audit complete")
    },
}
export const SLABS = {
    SILVER: {
        Start: 0,
        End: 100,
        Multiplier: 10,
        label: 'Silver',
        image: './silver.png'
    },
    GOLD: {
        Start: 101,
        End: 1250,
        Multiplier: 12.5,
        label: 'Gold',
        image: './gold.png'
    },
    DIAMOND: {
        Start: 1251,
        End: 7500,
        Multiplier: 15,
        label: 'Diamond',
        image: './diamond.png'
    },
    PLATINUM: {
        Start: 7501,
        End: 9999999999,
        Multiplier: 20,
        label: 'Platinum',
        image: './platinum.png'
    },
    allSlabs() {
        return [this.SILVER, this.GOLD, this.DIAMOND, this.PLATINUM]
    },
    findSlab(points) {
        console.log("Slab for", points)
        if (points >= this.SILVER.Start && points <= this.SILVER.End) {
            return this.SILVER;
        }
        if (points >= this.GOLD.Start && points <= this.GOLD.End) {
            return this.GOLD;
        }
        if (points >= this.DIAMOND.Start && points <= this.DIAMOND.End) {
            return this.DIAMOND;
        }
        if (points >= this.PLATINUM.Start && points <= this.PLATINUM.End) {
            return this.PLATINUM;
        }
        return this.SILVER;
    }
}
