import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import { store } from './store'
import { Provider } from 'react-redux'
import { Provider as PaperProvider, MD3LightTheme as DefaultTheme, Portal as PaperPortal } from 'react-native-paper';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from './screens/LoginScreen';
import TechnicianDashboard from './screens/TechnicianDashboard';
import AddCustomer from './screens/add-customer';
import firebase, { database } from './firebase-app'
import AdminDashboard from './AdminDashboard';
import MyWallet from './screens/technician/my-wallet';
import MemberRegistration from './screens/technician/registration';
import SelectLGAComponent from './screens/technician/lga-level1';
import SelectLGAValue from './screens/technician/lga-level2';
import AllTechnicians from './screens/admin/all-technicians';
import TakePictureComponent from './screens/take-photo';
import { useEffect, useState } from 'react';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import EditProfile from './screens/technician/edit-profile';
import * as Updates from 'expo-updates';
import LoyaltyPointSlab from './screens/technician/loyalty-point-slab';
import RedeemPoints from './screens/technician/redeem-points';
import AllCustomers from './screens/admin/all-customers';
import ViewCustomer from './screens/view-customer';
import ViewTechnician from './screens/admin/view-technician';
import ViewCustomerAdmin from './screens/view-customer-admin';
import Loader from './loader';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import AdminAppDashboard from './screens/AdminApp';
import ListTechnicians from './screens/admin/list-technicains';
import Feedback from './screens/technician/feedback';
import ListCustomers from './screens/admin/list-customers';
import AddProduct from './screens/admin/add-product';
import ViewProduct from './screens/admin/view-product';
import ListProducts from './screens/technician/list-products';
import DraftCustomers from './screens/technician/draft-customers';
import AdminReportPage from './screens/admin/reportpage';
import AdminReport from './screens/AdminReport';
import AdminViewWallet from './screens/admin/admin-view-wallet';
import AreaWiseReport from './screens/admin/area-report';
import { RootSiblingParent } from 'react-native-root-siblings';
import referralReport from './screens/technician/referral-report';
import bank from './screens/technician/bank';
import technicianPointAudit from './screens/technician/technician-point-audit';

//import * as fb from './firebase-app'

const Stack = createNativeStackNavigator();

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'tomato',
    secondary: 'yellow',
  },
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default function App() {
  let [loading, setLoading] = useState(false)
  async function getNotificationService() {
    setLoading(true)
    let token = await registerForPushNotificationsAsync();
    if (!token) {
      setLoading(false)
      return;
    }
    await AsyncStorage.setItem('token', token)
    let userData = await AsyncStorage.getItem('user')
    let user = JSON.parse(userData)
    if (!!user?.id) {
      await setDoc(doc(database, "users", user?.id), {
        token: token
      }, {
        merge: true
      })
    }
    setLoading(false)
    return token;
  }
  async function loadAdminProfile() {
    let adminUser = await getDoc(doc(database, "users", "ivxkzofvqtXt69Qhof2V"))
    let adminToken = adminUser.data().token;
    await AsyncStorage.setItem('admin_token', adminToken)
    console.log("Admin profile load complete!!")
  }
  async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        // alert('Failed to get push token for push notification!');
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
    } else {
      //alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    return token;
  }
  async function checkForUpdate() {
    try {
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        Updates.reloadAsync();
      }
    } catch (e) {
      // handle or log error
      console.log(e)
    }
  }
  useEffect(() => {
    console.log("Retrieving token")
    // checkForUpdate();
    loadAdminProfile();
    // getNotificationService().then((token) => {
    //   console.log("Received token", token)
    // }).catch((e) => {
    //   console.log("Error=>", e)
    // });
  }, [])
  return (
    <Provider store={store}>
      <PaperProvider theme={theme}>
        <PaperPortal>
          <RootSiblingParent>
            <NavigationContainer>
              <Stack.Navigator>
                <Stack.Screen name="login" component={LoginScreen} options={{ headerShown: false }} />
                <Stack.Screen name="member-registration" component={MemberRegistration} options={{ title: 'Technician Registration', headerShown: true }} />
                <Stack.Screen name="my-wallet" component={MyWallet} options={{ title: 'Reports', headerShown: true }} />
                <Stack.Screen name="tenician-dashboard" component={TechnicianDashboard} options={{ title: 'Dashboard', headerShown: false }} />
                <Stack.Screen name="admin-dashboard" component={AdminAppDashboard} options={{ title: 'Dashboard', headerShown: false }} />
                <Stack.Screen name="add-customer" component={AddCustomer} options={{ title: 'Add Customer', headerShown: true }} />
                <Stack.Screen name="select-state" component={SelectLGAComponent} options={{ title: 'Select State', headerShown: true }} />
                <Stack.Screen name="select-lga" component={SelectLGAValue} options={{ title: 'Select LGA', headerShown: true }} />
                <Stack.Screen name="admin-all-techs" component={AllTechnicians} options={{ title: 'Technicians', headerShown: true }} />
                <Stack.Screen name="take-photo" component={TakePictureComponent} options={{ title: 'Capture Photo', headerShown: true }} />
                <Stack.Screen name="edit-profile" component={EditProfile} options={{ title: 'Edit Profile', headerShown: true }} />
                <Stack.Screen name="loyalty-point-slab" component={LoyaltyPointSlab} options={{ title: 'Loyalty Point Slab', headerShown: true }} />
                <Stack.Screen name="point-audit-technician" component={technicianPointAudit} options={{ title: 'Point Audit Report', headerShown: true }} />
                <Stack.Screen name="redeem-points" component={RedeemPoints} options={{ title: 'Redeem Points', headerShown: true }} />
                <Stack.Screen name="referral-report" component={referralReport} options={{ title: 'Referral Report', headerShown: true }} />
                <Stack.Screen name="bank" component={bank} options={{ title: 'Bank Account Details', headerShown: true }} />
                <Stack.Screen name="all-customers" component={AllCustomers} options={{ title: 'Customers', headerShown: true }} />
                <Stack.Screen name="view-customer" component={ViewCustomer} options={{ title: 'Customer Details', headerShown: true }} />
                <Stack.Screen name="view-customer-admin" component={ViewCustomerAdmin} options={{ title: 'Customer Details', headerShown: true }} />
                <Stack.Screen name="view-technician" component={ViewTechnician} options={{ title: 'View Technician', headerShown: true }} />
                <Stack.Screen name="list-technician" component={ListTechnicians} options={{ title: 'Select Technician', headerShown: true }} />
                <Stack.Screen name="list-customer" component={ListCustomers} options={{ title: 'Select Customer', headerShown: true }} />
                <Stack.Screen name="feedback" component={Feedback} options={{ title: 'Feedback', headerShown: true }} />
                <Stack.Screen name="add-product" component={AddProduct} options={{ title: 'Add Product', headerShown: true }} />
                <Stack.Screen name="view-product" component={ViewProduct} options={{ title: 'View Product', headerShown: true }} />
                <Stack.Screen name="list-products" component={ListProducts} options={{ title: 'Products', headerShown: true }} />
                <Stack.Screen name="draft-customers" component={DraftCustomers} options={{ title: 'Drafts', headerShown: true }} />
                <Stack.Screen name="admin-report" component={AdminReport} options={{ title: 'Customer Report', headerShown: true }} />
                <Stack.Screen name="admin-points-report" component={AdminViewWallet} options={{ title: 'Points Report', headerShown: true }} />
                <Stack.Screen name="admin-area-report" component={AreaWiseReport} options={{ title: 'Area Wise Report', headerShown: true }} />
              </Stack.Navigator>
            </NavigationContainer>
          </RootSiblingParent>
        </PaperPortal>
      </PaperProvider>
      {loading && <Loader />}
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
